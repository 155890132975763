.LoadingHolder {
  // by default, LoadingSpinner should just be centered
  width: 30px;
  height: 20px;
  margin: var(--spacing-base) auto;
  }

.LoadingSpinner {
  position: relative;
  height: 100%;
  span {
    position: absolute;
    top: 40%;
    background-color: var(--accent);
    width: 25%;
    height: 20%;
    border-radius: 10%;
    backface-visibility: hidden;
  }
  span.first {
    left: 0;
    animation: kitt-first 1s infinite;
  }
  span.second {
    left: 50%;
    transform: translateX(-50%);
    animation: kitt-second 1s infinite;
  }
  span.third {
    right: 0;
    animation: kitt-third 1s infinite;
  }
}

@keyframes kitt-first {
  0%   { top: 40%; height: 20%; }
  25%  { top: 0; height: 100%; }
  100% { top: 40%; height: 20%; }
}

@keyframes kitt-second {
  0%   { top: 40%; height: 20%; }
  50%  { top: 0; height: 100%; }
  100% { top: 40%; height: 20%; }
}

@keyframes kitt-third {
  0%   { top: 40%; height: 20%; }
  75%  { top: 0; height: 100%; }
  100% { top: 40%; height: 20%; }
}