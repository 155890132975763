.ConfirmTile {
  overflow: hidden;
  .TileHeader {
    border-bottom: 1px solid var(--primary-l6);
    margin-top: calc(16px * -1);
    margin-left: calc(16px * -1);
    margin-right: calc(16px * -1);
    padding: var(--spacing-m1) var(--spacing-base);
    color: var(--primary-d1);
    font-weight: var(--bold);
  }
  .ConfirmBody {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin-bottom: var(--spacing-m1);
        small {
          color: var(--grey-d1);
          display: block;
        }
        p {
          margin: 0 0 0.25rem 0;
        }
      }
      &.SplitList {
        margin-left: calc(16px * -1);
        margin-right: calc(16px * -1);
        li {
          padding-left: var(--spacing-base);
          padding-right: var(--spacing-base);
          span:last-child {
            float: right;
          }
        }
      }
    }
    hr {
      border-color: var(--primary-l6);
    }
  }
}