@import 'utils/style/mixins.scss';

.CloseButton {
  @include reset-button;
  border: 2px solid #fff;
  border-radius: 50%;
  transition: border-color 191ms ease-out;
  svg {
    display: block;
    width: 46px;
    fill: #fff;
    transition: transform 151ms ease-out;
  }
  &:hover,
  &:focus {
    border-color: var(--accent-l5);
    svg {
      fill: var(--accent-l5);
      transform: scale(1.175);
    }
  }
}