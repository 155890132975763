.FullScreenLoadingSpinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .LoadingSpinner {
    position: absolute;
    width: 80px;
    height: 30px;
    left: 50%;
    top: 40%;
    transform: translateX(-45px);
  }
}