.HeaderImage {
  position: relative;
  border-top: 1px solid var(--primary);
  border-bottom: 1px solid var(--primary);
  img {
    width: 200%;
    margin-left: -100%;
  }
  .Promo {
    position: absolute;
    bottom: var(--spacing-p3);
    width: 100%;
    text-align: center;
    h2,
    h3 {
      color: #fff;
      text-shadow: 0 0 4px var(--primary-d8);
      margin: 0 0 var(--spacing-m3) 0;
    }
  }
}

@media (min-width: 768px) {
  .HeaderImage {
    img {
      width: 100%;
      margin-left: 0;
    }
    .Promo {
      text-align: left;
      bottom: 40%;
      h2 { font-size: 35px }
      h3 { font-size: 28px }
    }
  }
}