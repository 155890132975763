@import 'utils/style/mixins.scss';

button.NavTrigger {
  @include reset-button;
  border-radius: var(--small-radius);
  transition: border-color 197ms ease-out;
  width: 40px;
  height: 50px;
  padding-left: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
  transition: border 233ms ease-out;
  .bar {
    display: block;
    width: 26px;
    height: 3px;
    background: var(--accent-d1);
    margin-bottom: 5px;
    transition: background 233ms ease-out;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:hover {
    .bar {
      background: var(--accent-d3);
    }
  }
  &:focus {
    border: 1px solid var(--primary-l3);
  }
}