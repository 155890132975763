// every page must follow this structure:
// .Wrapper
//   - .Header
//   - main.main
//   - .Footer

.Wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: opacity 0.75s ease-out;
  &.loading { opacity: 0 }
  &.modalActive {
    overflow: hidden;
    padding-right: 17px;
  }
}

.Wrapper {
  .Header {} 
  main#main {
    flex-grow: 1;
  }
  .Footer {}
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 600px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 1000px) {
  .container {
    max-width: 1000px;
  }
}

// split design

body.split-page {
  .Wrapper {
    border: 3px solid orange;
  }
}

.NoPolicies {
  text-align: center;
  margin-top: 2rem;
  font-size: x-large;
}
