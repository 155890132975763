.field.date input {
  display: none;
}

.DatePicker {
  margin-left: -5px;
  margin-right: -5px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.DatePicker .DateHdr,
.DatePicker .DateCell {
  text-align: center;
}

.DatePicker .DateCell {
  position: relative;
  background: transparent;
  border: none;
  height: 55px;
  outline: none;
  &:focus {
    span {
      background: var(--accent);
      box-shadow: 0 0 0 3px var(--accent-l6);
      color: #fff;
    }
  }
}

.DatePicker .DateCell:hover {
  cursor: pointer;
}

.DatePicker .DateCell span {
  transform: translate(-50%, -50%);
  color: var(--grey-d2);
  line-height: 30px;
  border-radius: 15px;
  font-size: 0.8rem;
  &:hover {
    background: var(--accent-l8);
  }
}

.DatePicker .DateCell.Selected span {
  background: var(--accent);
  color: #fff;
  font-weight: 600;
}

.DateCell.BeforeMin,
.DateCell.AfterMax {
  &:hover,
  &:focus,
  &:active {
    span {
      color: var(--grey-l2);
      background: transparent;
      box-shadow: none;
      cursor: no-drop;
    }
  }
}

.DateCell.BeforeMin span,
.DateCell.AfterMax span {
  color: var(--grey-l2);
}

.DateCell.Today span {
  color: var(--primary-d2);
  font-weight: bold;
  // &:hover {
  //   cursor: no-drop;
  // }
}

.DateCell.Selected.Today span {
  background: var(--accent-l8);
  color: var(--accent-d2);
}

/* month nav */

.MonthNav {
  position: relative;
  margin-bottom: var(--spacing-m1);
  text-align: center;
  color: var(--primary);
  padding: 0.5rem;
  font-weight: var(--semibold);
}

.MonthNav .Prev,
.MonthNav .Next {
  position: absolute;
  top: 0;
  bottom: 0;
  appearance: none;
  background: transparent;
  outline: none;
  border: none;
  svg {
    width: 25px;
    height: 25px;
    vertical-align: middle;
    fill: var(--accent);
  }
  &:hover {
    cursor: pointer;
    svg {
      fill: var(--secondary-d1);
    }
  }
  &:focus {
    svg {
      fill: var(--accent-l5);
    }
  }
}

.MonthNav .Prev { left: 0; padding-right: var(--spacing-base); }
.MonthNav .Next { right: 0; padding-left: var(--spacing-base); }