.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  z-index: 50;
}

.modal-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 768px;
  position: relative;
}

.modal-inner {
  padding: 16px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.video-container {
  position: relative;
  aspect-ratio: 16 / 9;
}

.video-container video {
  width: 100%;
  height: 100%;
}

.video-container iframe {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: white;
}

.close-button svg {
  width: 24px;
  height: 24px;
  color: #1f2937;
}
