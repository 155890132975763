.ArrayItems {
  padding-top: var(--spacing-m1);
  border-top: 1px solid var(--primary-l4);
  .ArrayItem {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-m2);
    .Btn {
      svg {
        width: 20px;
        fill: var(--accent-d1);
      }
    }
    &:nth-child(even) {
      color: var(--copy-colour-dark);
      background: var(--primary-l8);
    }
  }
  .ArrayPlaceholder {
    display: block;
    padding: var(--spacing-m2);
    color: var(--grey);
  }
}

.field.has-error {
  .ArrayItems {
    border-color: var(--warning-d1);
  }
}
