.Header {
  .container {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .lngToggle {
    align-items: center;
    display: flex;
  }
  .Btn {
    margin-left: 10px;
    text-decoration: underline;
  }
}

.Header.Authed {
  .container {
    flex-direction: row-reverse;
    .Masthead { display: none }
  }
}

@media (min-width: 768px) {
  .Header.Authed {
    .container {
      flex-direction: row;
      .Masthead { display: inline-block }
      .MakeARequest { margin-left: auto }
      .NavTrigger { margin-left: var(--spacing-base) }
    }
  }
}
