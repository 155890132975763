@import 'style/vars.scss';
@import 'style/reset.scss';
@import 'style/typography.scss';
@import 'style/utils.scss';
@import 'style/layout.scss';
@import 'style/forms.scss';
@import 'style/general.scss';
@import 'style/modals.scss';

* {
  box-sizing: border-box;
}

body {
  background: var(--body-bg);
}