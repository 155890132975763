body {
  #olark-wrapper .olark-launch-button-wrapper {
    transition: bottom 121ms ease-out !important;
    z-index: 0 !important;
  }
  #olark-wrapper .olark-launch-button-wrapper {
    bottom: 79px !important;
    opacity: 1 !important;
  }
  #olark-wrapper #olark-container {
    bottom: 150px !important;
    margin-top: 124px !important;
    height: 50% !important;
    top:initial !important;
    z-index: 0 !important;
  }
  @media screen and (min-width: 600px) {
    #olark-container {
      width: 35% !important;
    }
  }
}

body:has(.sliding-nav) #olark-wrapper .olark-launch-button-wrapper {
  transition: bottom 0.3s ease-in-out !important;
}

body:has(.sliding-nav.expanded) #olark-wrapper .olark-launch-button-wrapper {
  bottom: 240px !important;
}

body:has(.sliding-nav.expanded.lastpage) #olark-wrapper .olark-launch-button-wrapper {
  bottom: 220px !important;
}