.NewRequest {
  .Tile {
    width: 100%;
    transition: box-shadow 232ms ease-out;
    min-height: 110px;
    h4 {
      margin-bottom: 0.25rem;
    }
    &:hover {
      box-shadow: 4px 4px 5px rgba(0,0,0,0.25);
    } 
  }
}


.FormNav {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  > div.container {
    display: flex;
    justify-content: space-between;
    background: var(--primary);
    padding: var(--spacing-m1) var(--spacing-base) var(--spacing-base);
    border-radius: 5px 5px 0 0;
    margin-left: var(--spacing-base);
    margin-right: var(--spacing-base);
    .Btn.NeedHelp {
      color: var(--primary-l8);
      font-weight: var(--bold);
    }
  }
}

@media (min-width: 768px) {
  .FormNav {
    > div.container {
      margin: 0 auto;
    }
  }
}

.sliding-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  transition: height 0.3s ease-in-out;
  height: 64px;
  z-index: 10;
  overflow: hidden;
  > div.container {
    padding-left: 10px;
    padding-right: 10px;
    background: var(--primary);
    border-radius: 5px 5px 0 0;
    .Btn.NeedHelp {
      color: var(--primary-l8);
      font-weight: var(--bold);
    }
  }
}

.sliding-nav.expanded {
  height: 240px;
}

.sliding-nav.expanded.lastpage {
  height: 200px;
}

.main-nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  height: 64px;
}

.expanded-content {
  height: 256px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  display: flex;
  justify-content: center;
}

.expanded .expanded-content {
  opacity: 1;
  visibility: visible;
}

.expanded-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 8px;
}

.manage-wrapper {
  text-align: center;
}

.continue-wrapper {
  text-align: right;
}

.Btn.manage {
  display: inline-flex;
  align-items: center;
}


@media (max-width: 600px) {
  .main-nav {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    height: 64px;
  }
  
  .nav-button {
    padding: 8px 12px;
    font-size: 14px;
  }

  .sliding-nav.expanded {
    height: 240px; 
  }
  
  .sliding-nav.expanded.lastpage {
    height: 200px; 
  }

  .expanded-content {
    height: 256px;
  }

  .expanded-buttons .nav-button {
    padding: 10px 16px;
  }
  .Btn.continue {
    width: 100%;
  }
  .Btn.back {
    width: 100%;
  }
}


@media (max-width: 400px) {
  .Btn.continue {
    width: 100%;
    font-size: 15px;
    padding: 7px var(--spacing-m1);
  }
  .Btn.back {
    width: 100%;
    font-size: 15px;
    padding: 7px var(--spacing-m1);
  }
}