* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, b, strong {
	font-weight: var(--bold);
}

small {
  font-size: var(--font-m1);
}

em, i {
	font-style: italic;
}

p {
	margin:0 0 1.5em 0;
}

blockquote {
	margin:0 1.5em 1.5em 1.5em;
}

cite {
	font-style: italic;
}

li ul, li ol {
	margin:0 1.5em;
}

ul, ol {
	margin:0 1.5em 1.5em 1.5em;
}

ul {
	list-style-type:disc;
}

ol {
	list-style-type:decimal;
}

ol ol {
	list-style: upper-alpha;
}

ol ol ol {
	list-style: lower-roman;
}

ol ol ol ol {
	list-style: lower-alpha;
}

dl {
	margin:0 0 1.5em 0;
}

dl dt {
	font-weight:bold;
}

dd {
	margin-left:1.5em;
}

table {
	margin-bottom:1.4em;
	width:100%;
}

th {
	font-weight:bold;
}

th, td, caption {
	padding:4px 10px 4px 5px;
}

tfoot {
	font-style:italic;
}

sup, sub {
	line-height:0;
}

abbr, acronym {
	border-bottom: 1px dotted;
}

address {
	margin:0;
	font-style:normal;
}

del {
	text-decoration: line-through;
}

img {
  display: block;
	width: 100%;
}

hr {
  height: 0;
  overflow: hidden;
  border: none;
  border-top: 1px solid #fff;
  margin: 2rem 0 2rem 0;
}

button {
  color: inherit;
  font: inherit;
  font-size: inherit;
  line-height: inherit;
  &:hover {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
  color: var(--primary-d4);
}