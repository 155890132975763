.zebra-list {
  list-style: none;
  margin: 0 0 var(--spacing-p1) 0;
  padding: 0;
  li {
    padding: var(--spacing-m2) var(--spacing-m3);
    margin-bottom: var(--spacing-m2);
    &:nth-child(even) {
      color: var(--copy-colour-dark);
      background: var(--primary-l8);
    }
  }
}

.EmailAnimation {
  position: relative;
  border: 3px solid #ccc;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-bottom: 1.25rem;
  overflow: hidden;
  svg {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    display: inline-block;
    width: 65px;
    -webkit-animation: emailsend 2.5s infinite;
    animation: emailsend 2.5s infinite;
  }
}

@keyframes emailsend {
  0% { left: 5px }
  20% { left: 5px }
  100% { left: 125px; opacity: 0.25; }
}

@media (min-width: 768px) {
  .EmailAnimation {
    float: left;
    margin-right: 1rem;
  }
}