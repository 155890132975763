body {
  font-family: 'Muli', 'Helvetica Neue', Arial, sans-serif;
  font-size: var(--font-base);
  line-height: var(--line-height-base);
  letter-spacing: 0.5px;
  color: var(--copy-colour);
  font-weight: var(--regular);
}

h1,
h2,
h3,
h4 {
  font-weight: var(--bold);
  color: var(--primary-color);
  margin: 0 0 var(--spacing-base) 0;
}

h5, .h5 { font-size: var(--font-m1) }
h4, .h4 { font-size: var(--font-base) }
h3, .h3 { font-size: var(--font-p1) }
h2, .h2 { font-size: var(--font-p2) }

// slightly hacky way to adjust line height in para txt with innline btns

.InlineButtons {
  .Pill {
    margin-bottom: 8px;
  }
}

// pc tenant product names need to be capitalized at start of sentence

.tenant-product-name i {
  font-style: normal;
}
