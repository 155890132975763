.QuoteVehicles {
  .QuoteVehicle {
    display: flex;
    justify-content: flex-start;
    padding: var(--spacing-m1);
    .Btn {
      margin-left: auto;
      svg {
        width: 20px;
        fill: var(--accent-d1);
      }
    }
    p {
      margin: 0;
    }
  }
}

.field.select.loading {
  select {
    opacity: 0.5;
    cursor: no-drop;
  }
}

.VehicleToAdd {
  p {
    margin: 0;
  }
}
