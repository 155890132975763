.Nav {
  text-transform: uppercase;
  width: 85%;
  margin: 100px auto;
  hr {
    width: 30%;
  }
}

.Nav .Link {
  display: block;
  font-size: var(--font-p2);
  color: var(--primary-l8);
  margin-bottom: var(--spacing-m1);
  letter-spacing: 0.1rem;
  svg {
    width: 14px;
    fill: var(--primary);
    opacity: 0;
    transition: margin-left 171ms ease-out, opacity 171ms ease-out;
  }
  &:hover {
    color: var(--primary-l4);
    svg {
      opacity: 1;
      margin-left: var(--spacing-m1);
    }
  }
  &.Highlight {
    font-weight: var(--bold);
  }
}

.Nav .NavPolLink {
  font-size: var(--font-base);
}

.Nav .Masthead {
  svg {
    width: auto;
    height: 40px;
    margin-bottom: var(--spacing-p2);
    .default-dark {
      fill: #fff;
    }
  }
}

.Nav .CloseButton {
  position: absolute;
  top: var(--spacing-p2);
  right: var(--spacing-p2);
}

.Nav .LogoutButton {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: var(--spacing-p1);
}

@media (min-width: 768px) {
  .Nav .NavContent {
    width: 400px;
  }
}
