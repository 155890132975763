.ErrorFlash {
  position: sticky;
  top: var(--spacing-base);
  padding: var(--spacing-base);
  z-index: 1;
  border: 1px solid var(--warning);
  background: var(--warning-l8);
  color: var(--warning-d5);
  margin-bottom: var(--spacing-base);
  svg {
    width: 22px;
    height: 22px;
    fill: var(--warning-d1);
    vertical-align: middle;
    margin-right: var(--spacing-m1);
  }
  span {
    vertical-align: middle;
    a {
      text-decoration: underline;
      color: var(--error-d3);
      &:hover {
        text-decoration: none;
      }
    }
  }
}
