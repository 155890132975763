.FakeButton {
  position: relative;
  padding: 0;
  .Popover {
    opacity: 0;
    top: -32px;
    transition: all 231ms ease-out;
  }
  &:hover,
  &:active {
    .Popover {
      opacity: 1;
      top: -42px;
    }
  }
  &.FakeButton {
    &:hover {
      cursor: pointer;
    }
  }
}