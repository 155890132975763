.MakeARequest.Pill {
  position: relative;
  font-size: var(--font-m1);
  &.Existing {
    &:after {
      content: '1';
      position: absolute;
      top: -10px;
      right: -10px;
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      background: #fff;
      border: 1px solid var(--primary);
      border-radius: 13px;
      color: var(--primary-d6);
    }
  }
}