.Suspension {
  padding-top: 1rem;
}

.AnotherSuspension {
  margin: 1rem -10px 1rem -10px;
  padding: 3px 0 3px 10px;
  background: #f1f1f1;
  color: #666;
}

.btn.has-tabs.checked button {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  padding-bottom: 14px;
}

.btn.tab-btn {
  span:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid #999;
    border-radius: 6px;
    margin-right: 6px;
  }
  &.checked {
    span:before {
      background: #ccc;
    }
  }
}

.UsageConfirm, .addressConfirm {
  .SimpleList {
    li {
      margin-bottom: var(--spacing-base);
      h5 {
        margin: 0 0 var(--spacing-m3) 0;
      }
      .UsageKms, .propChange {
        color: var(--grey-l2);
        display: block;
      }
    }
  }
}