.Divider {
  hr {
    position: relative;
    border-color: var(--accent-l7);
    overflow: visible;
    margin: var(--spacing-p3);
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      background: var(--accent-l5);
      border-radius: 10px;
    }
  }
  &.Small {
    hr {
      margin: var(--spacing-p3) 100px;
      &:after {
        top: -5px;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
      }
    }
  }
}