.BillingTile {
  .NextPayment {
    position: absolute;
    top: var(--spacing-base);
    right: var(--spacing-base);
    text-align: right;
    max-width: 150px;
    .NoBalance {
      display: inline-block;
      text-transform: uppercase;
      color: var(--success-d1);
      font-weight: var(--bold);
      margin-top: var(--spacing-m3);
    }
    .Balance {
      display: block;
      font-size: var(--font-p4);
      line-height: 35px;
      color: var(--success);
      font-weight: var(--bold);
    }
    .PaymentPlan {
      color: var(--grey);
      font-size: 0.9rem;
      display: block;
    }
    .DueOn {
      font-weight: var(--bold);
      display: inline-block;
      text-transform: uppercase;
      margin-bottom: var(--spacing-m2);
      i {
        font-weight: var(--regular);
        font-style: normal;
        color: var(--grey);
      }
    }
  }
}

.BillingSchedule.zebra-list {
  margin-top: var(--spacing-p2);
  li {
    .Amt {
      float: right;
    }
    &:nth-child(even) {
      color: var(--primary-d8);
    }
  }
}

@media (min-width: 768px) {
  .BillingTile {
    .NextPayment {
      max-width: none;
    }
  }
}