.Tile {
  display: block;
  position: relative;
  padding: var(--spacing-base);
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  margin-bottom: var(--spacing-p1);
  min-height: 75px;
  border: none;
  text-align: left;
  color: inherit;
}

.Tile.Warning {
  border: 1px solid var(--warning);
  background: var(--warning-l8);
  color: var(--warning-d5);
  box-shadow: none;
  .TileIcon {
    svg {
      fill: var(--warning-d1);
    }
  }
  p {
    margin: 0;
  }
  a,
  .Link {
    color: var(--warning-d8);
    &:hover {
      text-decoration: underline;
    }
  }
}

.Tile.HasIcon {
  .TileHeader {
    display: inline-block;
    vertical-align: middle;
    max-width: 75%;
    strong {
      text-transform: uppercase;
    }
  }
}

.TileBody {
  clear: both;
  padding-top: var(--spacing-p1);
}

.Tile .TileIcon {
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  margin-right: var(--spacing-base);
  &.Small { width: 30px }
  svg {
    display: block;
    width: 100%;
    fill: var(--accent-d2);
  }
}

.Tile.HasSmallIcon {
  display: flex;
  .TileIcon {
    svg {
      width: 25px;
    }
  }
}
