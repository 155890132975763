.LogoutModalBody {
  color: #fff;
  padding-top: 75px;
  strong {
    display: block;
    color: #fff;
    margin-bottom: var(--spacing-base);
  }
  > .Btn {
    float: left;
    margin-right: var(--spacing-base);
  }
}