@media (min-width: 768px) {
  .PoliciesBlock {
    .Tile {
      width: 48.5%;
      float: left;
      &:nth-child(odd) {
        margin-right: 3%;
      }
    }
  }
}

.PoliciesBlock {
  .Tile:not(.Light) {
    .Footer {
      background: transparent;
      padding-top: 0;
      padding-bottom: var(--spacing-base);
      .Link.Pill.Dark {
        background: var(--primary-d1);
        &:hover {
          background: var(--primary-d2);
          color: var(--primary-l2);
        }
      }
    }
  }
}

.DashboardTop {
  background: var(--body-bg);
  padding-top: var(--spacing-p1);
  padding-bottom: var(--spacing-p1);
  margin-bottom: var(--spacing-p2);
}