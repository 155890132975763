.MakePaymentTile {
  flex-direction: row;
  flex-wrap: wrap;
  .TileHeader {
    strong {
      line-height: 25px;
    }
  }
  .TileBody {
    padding-top: var(--spacing-m2);
    .Link {
      display: inline;
    }
  }
}

.RequirementList {
  ul.Required {
    margin-bottom: var(--spacing-p3);
  }
}