.LicenceCard {
  display: block;
  background: #fff;
  border-radius: var(--border-radius-base);
  padding: var(--spacing-m1);
}

.Suspension {
  padding-top: 1rem;
}

.AnotherSuspension {
  margin: 1rem -10px 1rem -10px;
  padding: 3px 0 3px 10px;
  background: #f1f1f1;
  color: #666;
}

.btn.has-tabs.checked button {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  padding-bottom: 14px;
}

.btn.tab-btn {
  span:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 1px solid #999;
    border-radius: 6px;
    margin-right: 6px;
  }
  &.checked {
    span:before {
      background: #ccc;
    }
  }
}

// licence display

.LicenceTile {
  .TileHeader {
    .Btn {
      float: right;
    }
  }
  .TileBody {
    .LicenceButton {
      width: 100%;
      border: 1px solid var(--primary-l4);
      border-radius: 4px;
      font: inherit;
      line-height: inherit;
      color: inherit;
      padding: var(--spacing-m1);
      background: #fff;
      outline: none;
      transition: border 197ms ease-out;
      margin-bottom: var(--spacing-base);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        font-weight: var(--bold);
        display: inline-block;
        min-width: 150px;
        text-align: left;
      }
      svg {
        width: 20px;
        fill: var(--primary);
      }
    }
  }
  &.success {
    border: 1px solid var(--success-d2);
    background: var(--success-l7);
  }
  &.error {
    border: 1px solid var(--warning-d2);
    background: var(--warning-l8);
  }
  .TierErrors {
    color: var(--warning-d5);
    svg {
      width: 24px;
      vertical-align: middle;
      fill: var(--warning-d5);
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.ModalInner {
  .LicenceDate {
    padding-top: 50px;
  }
}