.Messaging {
  position: relative;
  border: 1px solid var(--secondary);
  margin-bottom: var(--spacing-base);
  padding: var(--spacing-base);
  padding-left: 60px;
  font-size: var(--font-m1);
  color: var(--secondary-d2);
  border-radius: var(--border-radius-base);
  background: rgba(255,255,255,0.25);
  svg {
    position: absolute;
    top: var(--spacing-base);
    left: var(--spacing-base);
    width: 30px;
  }
  &.warning {
    svg {
      fill: var(--secondary);
    }
  }
  &.error {
    border-color: var(--accent2);
    color: var(--accent2-d2);
    svg {
      fill: var(--accent2);
    }
  }
}