.HomePage {
  .LoginOrSignup {
    margin-top: -25px;
    .LoginOrSignupToggle {
      text-align: center;
      margin-bottom: var(--spacing-p1);
      button,
      .Link {
        border: none;
        padding: 0 var(--spacing-p1);
        margin: 0;
        background: var(--primary);
        line-height: 50px;
        color: #fff;
        text-transform: uppercase;
        font-size: var(--font-m1);
        font-weight: var(--bold);
        letter-spacing: 1px;
        text-decoration: none;
        &:first-child { border-radius: var(--spacing-p1) 0 0 var(--spacing-p1) }
        &:last-child { border-radius: 0 var(--spacing-p1) var(--spacing-p1) 0 }
      }
      button {
        background: var(--primary-d1);
      }
    }
  }
}

@media (max-width: 500px) {
  .LoginOrSignupToggle {
    display: flex;
    justify-content: center;
    text-wrap-mode: nowrap;
  }
}

.HomeBlock {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: var(--spacing-base);
      h4 {

      }
      p {
        svg {
          width: 50px;
          float: left;
          fill: var(--accent);
          margin: 0 var(--spacing-base) var(--spacing-base) 0;
        }
      }
    }
  }
  p {
    button {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}