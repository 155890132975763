@import 'utils/style/mixins.scss';

.Btn {
  @include reset-button;
}

.Link {
  @include reset-button;
}

.Btn,
.Link {
  &:hover {
    color: var(--accent-d2);
  }
  &.Light {
    color: var(--primary-l8);
    &:hover {
      color: var(--primary-l6);
    }
  }
  &:focus {
    text-decoration: underline;
  }
  transition: background 123ms ease-out,
              color 123ms ease-out,
              box-shadow 123ms ease-out;
}

.Btn.Pill,
.Link.Pill {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background: var(--primary);
  color: var(--primary-l8);
  padding: var(--spacing-m2) var(--spacing-base);
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  text-decoration: none;
  min-width: 95px;
  text-align: center;
  &:hover {
    background: var(--primary-l1);
  }
  &.Small {
    font-size: var(--font-m1);
    padding: var(--spacing-m3) var(--spacing-m1);
  }
  &.Light {
    background: var(--primary-l8);
    color: var(--primary-d4);
    &:hover {
      background: var(--primary-l6);
    }
  }
  &.Accent {
    background: var(--accent-d1);
    &:hover {
      background: var(--accent-l1);
    }
  }
  &:focus {
    box-shadow: 0 0 0 4px var(--primary-l6);
  }
  &.Transparent {
    background: transparent;
    color: var(--primary-d2);
    &.Light {
      color: var(--primary-l6);
      &:hover {
        background: transparent;
        color: var(--primary-l8);
        box-shadow: none;
      }
    }
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  &.Accent {
    &:focus {
      box-shadow: 0 0 0 4px var(--accent-l6);
    }
  }
}

.Btn,
.Link {
  .LoadingHolder {
    margin: 0 var(--spacing-base);
    .LoadingSpinner {
      span {
        background: var(--primary-l6);
      }
    }
  }
  &.Light {
    .LoadingHolder {
      .LoadingSpinner {
        span {
          background: var(--accent);
        }
      }
    }
  }
  &.Accent {
    .LoadingHolder {
      .LoadingSpinner {
        span {
          background: var(--accent-l8);
        }
      }
    }
  }
  &.Small {
    .LoadingHolder {
      height: 15px;
    }
  }
}

.Btn.Disabled,
.Link.Disabled {
  &:hover {
    cursor: no-drop;
  }
}

// form.io

.btn-wizard-nav-cancel,
.btn-wizard-nav-previous,
.btn-wizard-nav-next,
.btn.btn-primary {
  @include reset-button;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background: var(--primary);
  color: var(--primary-l8);
  padding: var(--spacing-m3) var(--spacing-m1);
  border-radius: 20px;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  text-decoration: none;
  min-width: 95px;
  text-align: center;
  &:hover {
    background: var(--primary-l1);
    color: #fff;
  }
}

.btn-wizard-nav-cancel {
  background: var(--grey-l4);
  &:hover {
    background: var(--grey-l3);
  }
}

@media (max-width: 500px) {
  .ResumeDeleteButtons {
    text-align: center;
  }

  .SubmitButtonAlign {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}