#be-frame {
  transition: bottom 0.3s ease-in-out !important;
  bottom: 76px !important;
}

body:has(.sliding-nav.expanded) #be-frame {
  bottom: 250px !important;
}

body:has(.sliding-nav.expanded.lastpage) #be-frame {
  bottom: 220px !important;
}
