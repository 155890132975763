.Modal {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.85);
  z-index: 20;
  overflow-y: auto;
  padding-bottom: var(--spacing-p1);
  .ModalInner {
    opacity: 0;
  }
  .CloseButton {
    position: absolute;
    top: var(--spacing-base);
    right: var(--spacing-base);
  }
}

.Modal {
  .ModalInner {
    opacity: 0;
    transition: opacity 1000ms ease-out;
  }
  &.Active {
    .ModalInner {
      opacity: 1;
    }
  }
}

.Modal {
  &.AnchorLeft {
    left: -100%;
    transition: left 162ms ease-out, opacity 162ms ease-out;
    &.Active {
      left: 0;
      opacity: 1;
    }
  }
}

.Modal {
  &.AnchorBottom {
    bottom: -100%;
    transition: bottom 162ms ease-out, opacity 162ms ease-out;
    &.Active {
      bottom: 0;
      opacity: 1;
    }
  }
}
