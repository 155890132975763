

/* The alert message box */
.alert {
  padding: 5px;
  color: black;
  margin: 5px;
  border-radius: 10px;
}

.messageWrapper {
  margin-left: 5px;
}

.warning{
  color: white;
  background-color: var(--warning);
}

.notice{
  color: white;
  background-color: var(--notice);
}

/* The close button */
.closebtn {
  color: white;
  float: right;
  font-size: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

/* Show more button */
.showMoreBtn {
  width: fit-content;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  background-color: rgb(0, 0, 0);
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.showMoreBtn:hover {
  background-color: rgb(0, 0, 0)
}