.Footer {
  background: var(--primary-d1);
  color: var(--primary-l8);
  padding-top: var(--spacing-p1);
  padding-bottom: var(--spacing-p1);
  span {
    display: block;
    i {
      font-style: normal;
    }
    .Link {
      color: inherit;
      &:hover {
        color: var(--secondary);
      }
    }
  }
}