@import 'utils/style/mixins.scss';

// .btn.btn-primary.btn-wizard-nav-submit {
//   display: none;
// }

// .btn.btn-primary.btn-wizard-nav-submit.visible {
//   display:block;
// }

// .formio-wizard-nav-container {
// 	display:flex;
// }

.Wrapper.nav-hidden .formio-wizard-nav-container {
  display: none;
}

.Wrapper .formio-wizard-nav-container {
  .btn-wizard-nav-submit {
    display: none;
  }
}

.Wrapper.submit-visible .formio-wizard-nav-container {
  .btn-wizard-nav-submit {
    display: inline-flex;
  }
}

// lower nav

.formio-wizard-nav-container {
  clear: both;
  display: flex;
  list-style: none;
  margin: 0;
  padding: var(--spacing-p1) 0;
  button {
    margin-right: var(--spacing-m2);
  }
}

.formio-wizard-nav-container {
  button.btn-wizard-nav-cancel {
    margin-right: var(--spacing-base);
  }
}

// top nav

nav ul.pagination {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  li {

  }
}

// hide top errors

.alert[id^="error-list"] {
  display: none !important;
}

// top nav
// targets elems with id like "wizard-12345-header

nav[id^="wizard"][id$="header"] {
  margin-bottom: var(--spacing-p2);
  ul {
    display: block;
    li {
      margin-right: var(--spacing-base);
      display: inline-block;
      button {
        @include reset-button;
      }
      &.active button {
        color: var(--primary-d1);
        text-decoration: underline;
      }
    }
  }
}

// final page cards

.card.border {
  border: 1px solid var(--primary-l6);
  margin-bottom: var(--spacing-p1);
  .card-header {
    background: var(--primary-l6);
    padding: var(--spacing-m3);
  }
  table {
    margin: 0;
    tr {
      td {
        border-bottom: 1px solid var(--primary-l7);
        padding: var(--spacing-m2) var(--spacing-m1);
        p {
          margin: 0;
        }
      }
      &:last-child {
        td {
          border: none;
        }
      }
    }
  }
}

// modal

.Wrapper .formio-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  > div {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    > .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        margin: 0;
      }
      .CloseButton {
        border-color: var(--primary);
        svg {
          fill: var(--primary);
          width: 25px;
        }
      }
    }
    p {
      width: 325px;
      height: 80px;
      padding: var(--spacing-base);
      margin: 0;
      border-top: 1px solid var(--primary-l7);
      border-bottom: 1px solid var(--primary-l7);
    }
    div {
      padding: var(--spacing-base);
    }
  }
}

.Wrapper.modal-active .formio-modal {
  display: block;
}
// quote btns

.btn-primary.quote-btn {
  float: left;
  margin-right: var(--spacing-m1);
}

// bottom padding

body.letsconnect .Wrapper .Site {
  padding-bottom: var(--spacing-p3);
}