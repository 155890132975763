.Popover {
  position: absolute;
  top: -42px;
  right: 0;
  height: 30px;
  line-height: 30px;
  background: rgba(0,0,0,0.75);
  padding: 0 0.5rem;
  font-size: 0.85rem;
  border-radius: 6px;
  text-transform: none;
  color: #fff;
  span {
    white-space: nowrap;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  &:before {
    // this ensure that there is no gap between the
    // hoverered div and the popover itself
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 10px;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 22px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 10px 0 10px;
    border-color: rgba(0,0,0,0.75) transparent transparent transparent;
  }
}