@mixin reset-button {
  position: relative;
  appearance: none;
  font: inherit;
  color: inherit;
  display: inline-block;
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  line-height: inherit;
  outline: none;
  text-decoration: none;
  &:hover {
    box-shadow: none;
    color: var(--primary);
  }
  &:active {
    top: 0;
  }
}

@mixin reset-field {
  font: inherit;
  color: var(--primary-d3);
  line-height: inherit;
  appearance: none;
  display: block;
  background: #fff;
  border: none;
  width: 100%;
  outline: none;
  border-radius: 0;
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
  &:focus {
    color: var(--primary-d3);
  }
}

@mixin hidden-input {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

@mixin bullet-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    padding-left: var(--spacing-base);
    margin-bottom: var(--spacing-base);
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
      width: 5px;
      height: 5px;
      background: var(--primary-d1);
    }
  }
}