:root {
  --font-m3: 11px;
  --font-m2: 13px;
  --font-m1: 15px;
  --font-base: 17px;
  --font-p1: 19px;
  --font-p2: 21px;
  --font-p3: 23px;
  --font-p4: 25px;
  --font-p5: 27px;
  --font-p6: 30px;
  --font-p7: 34px;

  --line-height-base: 24px;

  --primary-l6: #eee;
  --primary-l5: #ccc;
  --primary-l4: #bbb;
  --primary-l3: #aaa;
  --primary-l2: #999;
  --primary-l1: #888;
  --primary: #777;
  --primary-d1: #666;
  --primary-d2: #555;
  --primary-d3: #444;
  --primary-d4: #333;
  --primary-d5: #222;
  --primary-d6: #111;

  --accent-l7: hsl(276deg, 71%, 65%);
  --accent-l6: hsl(276deg, 71%, 60%);
  --accent-l5: hsl(276deg, 71%, 55%);
  --accent-l4: hsl(276deg, 71%, 50%);
  --accent-l3: hsl(276deg, 71%, 45%);
  --accent-l2: hsl(276deg, 71%, 40%);
  --accent-l1: hsl(276deg, 71%, 35%);
  --accent: hsl(276deg, 71%, 30%);
  --accent-d1: hsl(276deg, 71%, 25%);
  --accent-d2: hsl(276deg, 71%, 20%);
  --accent-d3: hsl(276deg, 71%, 15%);
  --accent-d4: hsl(276deg, 71%, 10%);
  --accent-d5: hsl(276deg, 71%, 5%);
  --accent-d6: hsl(276deg, 71%, 0%);

  --success-d8: #0d251a;
  --success-d7: #143727;
  --success-d6: #1a4a34;
  --success-d5: #215c42;
  --success-d4: #286e4f;
  --success-d3: #2e815c;
  --success-d2: #359369;
  --success-d1: #3ba676;
  --success:    #42B883;
  --success-l1: #55bf8f;
  --success-l2: #68c69c;
  --success-l3: #7bcda8;
  --success-l4: #8ed4b5;
  --success-l5: #a1dcc1;
  --success-l6: #b3e3cd;
  --success-l7: #c6eada;

  --warning-d8: #332602;
  --warning-d7: #4c3903;
  --warning-d6: #664c04;
  --warning-d5: #805f06;
  --warning-d4: #997207;
  --warning-d3: #b38508;
  --warning-d2: #cc9809;
  --warning-d1: #e6ab0a;
  --warning:    #ffbe0b;
  --warning-l1: #ffc523;
  --warning-l2: #ffcb3c;
  --warning-l3: #ffd254;
  --warning-l4: #ffd86d;
  --warning-l5: #ffdf85;
  --warning-l6: #ffe59d;
  --warning-l7: #ffecb6;
  --warning-l8: #fff9e7;

  --spacing-m3: 5px;
  --spacing-m2: 10px;
  --spacing-m1: 15px;
  --spacing-base: 20px;
  --spacing-p1: 30px;
  --spacing-p2: 40px;
  --spacing-p3: 50px;

  --copy-colour-light: var(--primary);
  --copy-colour: var(--primary-d2);
  --copy-colour-dark: var(--primary-d4);
  
  --body-bg: #f9f9f9;
}