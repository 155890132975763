@import 'utils/style/mixins.scss';

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.primary-bg    { background-color: var(--primary) }
.primary-l1-bg { background-color: var(--primary-l1) }
.primary-l2-bg { background-color: var(--primary-l2) }
.primary-l3-bg { background-color: var(--primary-l3) }
.primary-l4-bg { background-color: var(--primary-l4) }
.primary-l5-bg { background-color: var(--primary-l5) }
.primary-l6-bg { background-color: var(--primary-l6) }
.primary-l7-bg { background-color: var(--primary-l7) }
.primary-l8-bg { background-color: var(--primary-l8) }
.primary-d2-bg { background-color: var(--primary-d3) }

.white {
  color: #fff;
}

.primary {
  color: var(--primary);
}

.primary-l1 {
  color: var(--primary-l1);
}

.primary-l2 {
  color: var(--primary-l2);
}

.primary-d1 {
  color: var(--primary-d1);
}

.primary-d2 {
  color: var(--primary-d2);
}

.secondary {
  color: var(--secondary);
}

.secondary-l1 {
  color: var(--secondary-l1);
}

.secondary-d2 {
  color: var(--secondary-d2);
}

.accent-d2 {
  color: var(--accent-d2);
}

.grey-l1 {
  color: var(--grey-l1);
}

.grey-l2 {
  color: var(--grey-l2);
}

.grey-l3 {
  color: var(--grey-l3);
}

.grey-l4 {
  color: var(--grey-l4);
}

.margin-base-bottom {
  margin-bottom: var(--spacing-base);
}

.margin-m2-top {
  margin-top: var(--spacing-m2);
}

.padding-m2-top {
  padding-top: var(--spacing-m2);
}

.padding-m1-bottom {
  padding-bottom: var(--spacing-m1);
}

.subtle {
  color: var(--grey-d2);
  font-size: var(--font-m1);
}

.zero-margin {
  margin: 0;
}

.font-p1 {
  font-size: var(--font-p1);
}

.font-p2 {
  font-size: var(--font-p2);
}

.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

// https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

// titles

.form-title {
  display: block;
  margin-bottom: var(--spacing-base);
}

// simple bullet list

.bullet-list {
  @include bullet-list;
}