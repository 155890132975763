.BackToTop {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--primary);
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 300ms ease-out;
  svg {
    display: block;
    fill: var(--primary);
  }
  &.visible {
    opacity: 1;
  }
}