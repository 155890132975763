@mixin field-defaults {
  width: 100%;
  border: 1px solid var(--primary-l4);
  border-radius: 4px;
  font: inherit;
  line-height: inherit;
  color: inherit;
  padding: var(--spacing-m1);
  background: #fff;
  outline: none;
  transition: border 197ms ease-out;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  box-shadow: 0 0 100px white inset !important;
  color: inherit !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

// base form styling

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

// basic field reset

input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
textarea,
select,
.field.radio .field-wrapper,
.field.date-chooser .field-wrapper,
.field.slide-toggle .field-wrapper,
.field.tri-date .field-wrapper,
.field.duo-date .field-wrapper,
.field.multi-checkbox .field-wrapper,
.field.coverage-radio .field-wrapper {
  @include field-defaults;
}

// focus styling

.field.date-chooser .field-wrapper,
.field.slide-toggle .field-wrapper,
.field.radio .field-wrapper,
.field.tri-date .field-wrapper {
  &:focus-within,
  &:active {
    border: 1px solid var(--primary-d1);
  }
}
.field.duo-date .field-wrapper {
  &:focus-within,
  &:active {
    border: 1px solid var(--primary-d1);
  }
}

.field.select .field-wrapper,
.field.textarea .field-wrapper,
.field.text .field-wrapper {
  &:focus-within,
  &:active {
    input,
    select,
    textarea {
      border: 1px solid var(--primary-d1);
    }
  }
}

// fields

.field {
  margin-bottom: var(--spacing-p2);
}

.field .field-wrapper {
  position: relative;
}

label.main-label,
span.main-label {
  display: block;
  padding-top: var(--spacing-m3);
  margin-bottom: var(--spacing-m1);
}

// hide radio and toggle inputs


.field.radio,
.field.slide-toggle,
.field.multi-checkbox {
  .field-wrapper {
    .secondary-label {
      position: relative;
      input {
        position: absolute;
        right: 0;
        opacity: 0;
      }
    }
  }
}

// radio & multi-checkbox

.field.radio,
.field.multi-checkbox {
  .field-wrapper {
    padding: 0;
    overflow: hidden;
    .choices {
      display: flex;
      justify-content: space-between;
      .secondary-label {
        flex-grow: 1;
        padding: var(--spacing-m1) var(--spacing-m2) var(--spacing-m1) var(--spacing-m1);
        transition: background 131ms ease-out, box-shadow 131ms ease-out;
        border-right: 1px solid var(--primary-l6);
        &:last-child {
          border: none;
        }
        span {
          display: inline-block;
          vertical-align: middle;
        }
        &:first-child { margin-left: 0 }
        &:last-child { margin-right: 0 }
        &:hover,
        &.checked {
          background: var(--primary-l8);
        }
        &.disabled {
          background-color: var(--disabled);
          &:hover { 
            cursor: no-drop;
            background-color: var(--disabled);
          }
          &:before {
            background: var(--disabled);
          }
        }
      }
    }
  }
}

.field.radio,
.multi-checkbox {
  .field-wrapper {
    .choices {
      .secondary-label {
        &:before {
          content: '';
          display: inline-block;
          min-width: 14px;
          min-height: 14px;
          margin-right: var(--spacing-m1);
          background: #fff;
          box-shadow: 0 0 0 1px var(--primary-l3);
          vertical-align: middle;
          border-radius: 10px;
          transition: all 183ms ease-out;
        }
        &.checked {
          &:before {
            background: var(--success);
            // box-shadow: 0 0 0 3px var(--success-l4);
            transform: scale(1.2);
          }
        }
        &:hover { cursor: pointer }
        &.disabled {
          background-color: var(--disabled);
          &:hover { 
            cursor: no-drop;
            background-color: var(--disabled);
          }
          &:before {
            background: var(--disabled);
          }
        }
      }
    }
  }
}

// radio stacked

.field.radio.stacked,
.multi-checkbox.stacked {
  .field-wrapper {
    .choices {
      display: block;
      .secondary-label {
        display: block;
        border-right: none;
        border-bottom: 1px solid var(--primary-l6);
        &:last-child { border: none }
      }
    }
  }
}

@media (max-width: 500px) {
  .field.radio.stacked .field-wrapper .choices .secondary-label, .multi-checkbox.stacked .field-wrapper .choices .secondary-label {
    display: flex;
    align-items: center;
  }
}

// coverage radio

.field.coverage-radio {
  margin-bottom: var(--spacing-p1);
  .coverage-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    strong {
      font-size: 0.85rem;
    }
  }    
  .expanded-area {
    max-height: 0;
    overflow: hidden;
    transition: max-height 131ms ease-out;
    .coverage-choices {
      display: flex;
      margin-top: 0.5rem;
      border-top: 1px solid var(--primary-l6);
      .secondary-label {
        flex: auto;
        padding: 0.5rem 0;
      }
    }
    .help-copy {
      p {
        margin: 0;
        font-size: 0.85rem;
        line-height: 1.5;
      }
    }
    &.expanded {
      max-height: 300px;
      overflow: auto;
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.field.coverage-radio {
  .not-set { 
    color: var(--warning-d1);
    text-wrap-mode: nowrap;
  }
  .included { color: var(--success) }
  .not-included {
    color: var(--warning-d1);
    font-size: 1.25rem;
  }
}

@media (max-width: 500px) {
  .field.field.coverage-radio .not-set {
    text-wrap-mode: nowrap;
  }
}

// slide toggle

.field.slide-toggle {
  .field-wrapper {
    overflow: hidden;
    padding: 0px;
    .secondary-label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding: var(--spacing-m1) var(--spacing-m2) var(--spacing-m1) var(--spacing-m1);
      &:before {
        flex-grow: 0;
        flex-shrink: 0;
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: var(--spacing-m1);
        background: #fff;
        box-shadow: 0 0 0 1px var(--primary-l3);
        vertical-align: middle;
        border-radius: 10px;
        transition: all 183ms ease-out;
      }
      &.checked {
        &:before {
          background: var(--success);
          // box-shadow: 0 0 0 3px var(--success-l4);
          transform: scale(1.2);
        }
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
      &:hover { cursor: pointer }
    }
    &:hover,
    &.checked {
      background: var(--primary-l8);
    }
  }
}

.ToggleGroup {
  margin-bottom: var(--spacing-p2);
  .field.field.slide-toggle {
    margin-bottom: 0;
    &:first-child {
      .field-wrapper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:last-child {
      .field-wrapper {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
      }
    }
  }
}

// field errors

.field.has-error {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  select {
    border: 1px solid var(--warning-d1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.field.radio.has-error,
.field.date-chooser.has-error,
.field.slide-toggle.has-error,
.field.tri-date.has-error,
.field.duo-date.has-error,
.field.coverage-radio.has-error {
  .field-wrapper {
    border-color: var(--warning-d1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:focus-within {
    .field-wrapper {
      border-color: var(--warning-d4);
    }
    .field-error {
      border-color: var(--warning-d4);
    }
  }
}

.field.radio.has-error {
  .field-wrapper {
    .choices {
      .secondary-label {
        border-color: var(--warning-d1);
      }
    }
  }
}

.field.textarea.has-error,
.field.select.has-error,
.field.text.has-error {
  .field-wrapper {
    &:focus-within {
      input,
      select,
      textarea {
        border-color: var(--warning-d4);
      }
      .field-error {
        border-color: var(--warning-d4);
      }
    }
  }
}

.field-error {
  position: relative;
  padding: var(--spacing-m1) var(--spacing-m2) var(--spacing-m1) 40px;
  font-size: var(--font-m1);
  color: var(--warning-d5);
  font-weight: var(--semibold);
  background: var(--warning-l8);
  border: 1px solid var(--warning-d1);
  border-top: none;
  border-radius: 0 0 3px 3px;
  svg {
    position: absolute;
    left: var(--spacing-m1);
    width: 20px;
    fill: var(--warning-d3);
  }
}

.field.textarea {
  textarea {
    display: block;
  }
  .CharacterCount {
    position: absolute;
    bottom: var(--spacing-m2);
    right: var(--spacing-m2);
    font-size: var(--font-m1);
    color: var(--primary-l2);
    &.maxed {
      color: var(--warning-d2);
    }
  }
  &.show-counter {
    textarea {
      padding-bottom: var(--spacing-p2);
    }
  }
}

// form error

.form-error {
  position: relative;
  padding: var(--spacing-m1) var(--spacing-m2) var(--spacing-m1) 40px;
  font-size: var(--font-m1);
  color: var(--warning-d5);
  font-weight: var(--semibold);
  background: var(--warning-l8);
  border: 1px solid var(--warning-d1);
  border-radius: 3px;
  margin-bottom: var(--spacing-p1);
  svg {
    position: absolute;
    left: var(--spacing-m1);
    width: 20px;
    fill: var(--warning-d3);
  }
  ul {
    margin: 0;
    padding: 0 0 0 1rem;
    li {
      margin-bottom: 1rem;
    }
  }
  .Link {
    color: var(--warning-d7);
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

// policies radio

.field.radio.policies {
  .field-wrapper {
    .choices {
      .secondary-label {
        .PolicyIcon {
          float: left;
          margin-right: var(--spacing-base);
          svg {
            width: 40px;
            fill: var(--accent-d2);
          }
        }
        .PolicyDetails {
          margin-left: 55px;
          width: -webkit-fill-available;
          strong {
            display: block;
          }
          .CheckedIndicator {
            float: right;
            width: 14px;
            height: 14px;
            margin-right: var(--spacing-m3);
            background: #fff;
            box-shadow: 0 0 0 1px var(--primary-l4);
            vertical-align: middle;
            border-radius: 10px;
            transition: all 183ms ease-out;
          }
        }
        &:before {
          display: none;
        }
        &.checked {
          .PolicyDetails {
            .CheckedIndicator {
              background: var(--success);
              box-shadow: none;
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
}

// select

.field.select {
  .field-wrapper {
    select {
      appearance: none;
    }
    .select-chevron {
      position: absolute;
      top: var(--spacing-base);
      right: var(--spacing-base);
      pointer-events: none;
    }
  }
}

// text

.field.text,
.field.select {
  .field-wrapper {
    .LoadingHolder {
      position: absolute;
      top: 0;
      right: var(--spacing-base);
    }
  }
}

.field.select {
  .field-wrapper {
    .LoadingHolder {
      right: auto;
      left: calc(50% - 20px);
    }
  }
}

// text fields

.field.text {
  .field-wrapper {
    .Price,
    .Kms {
      position: absolute;
      top: 0;
      line-height: 46px;
      pointer-events: none;
    }
  }
}

// currency

.field.PriceField {
  .field-wrapper {
    input {
      padding-left: var(--spacing-p2);
    }
    .Price {
      left: var(--spacing-base);
    }
  }
}

// kms

.field.KmField {
  .field-wrapper {
    input {
      padding-right: var(--spacing-p2);
    }
    .Kms {
      right: var(--spacing-base);
    }
  }
}

// hidden field

.field.hidden {
  height: 0;
  overflow: hidden;
  visibility: hidden;
  margin: 0;
}

// typeahead field

.field.typeahead {
  .field-wrapper {
    .typeahead-list {
      background: #fff;
      border: 1px solid var(--primary-l4);
      border-top: none;
      border-radius: 0 0 4px 4px;
      li {
        margin: 0;
        padding: 0;
        .Btn {
          display: block;
          width: 100%;
          text-align: left;
          padding: var(--spacing-m1) var(--spacing-m1);
        }
      }
    }
  }
  &.list-visible {
    .field-wrapper {
      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

// array radio

.field.array-radio {
  .SubForm {

  }
}

// help copy

.help-copy {
  display: inline-block;
  padding-top: var(--spacing-m1);
  color: var(--grey-d1);
  font-style: italic;
}

.support-copy {
  display: inline-block;
  padding-bottom: var(--spacing-m1);
  color: var(--grey-d1);
  font-style: italic;
}

// tri date

.field.tri-date {
  .field-wrapper {
    padding: 0;
    overflow: hidden;
    input {
      width: 30%;
      border: none;
      font: inherit;
      line-height: inherit;
      color: inherit;
      padding: var(--spacing-m1);
      background: #fff;
      outline: none;
      text-align: center;
    }
    span {
      text-align: center;
      display: inline-block;
      width: 5%;
    }
  }
}

.field.duo-date {
  .field-wrapper {
    padding: 0;
    overflow: hidden;
    input {
      width: 47.5%;
      border: none;
      font: inherit;
      line-height: inherit;
      color: inherit;
      padding: var(--spacing-m1);
      background: #fff;
      outline: none;
      text-align: center;
    }
    span {
      text-align: center;
      display: inline-block;
      width: 5%;
    }
  }
}

// label help

.main-label {
  position: relative;
  &.has-help {
    padding-right: 30px;
    .Btn {
      position: absolute;
      right: 0;
      top: 0;
      svg {
        width: 20px;
        fill: var(--primary-d1);
      }
    }
  }
}


// form.io

div.form-group {
  padding: var(--spacing-m1) 0 var(--spacing-p1) 0;
  margin-bottom: 0;
}

label.col-form-label {
  display: block;
  margin-bottom: 15px;
  margin-bottom: var(--spacing-m1);
}

// hidden fields

.formio-hidden {
  display: none;
  margin: 0;
  padding: 0;
}

.formio-component-hidden {
  display: none;
  margin: 0;
  padding: 0;
}

// error state

.form-group.has-error,
.form-group.has-message {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea,
  select {
    border: 1px solid var(--warning-d1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .formio-errors {
    position: relative;
    padding: var(--spacing-m1) var(--spacing-m2);
    font-size: var(--font-m1);
    color: #805f06;
    color: var(--warning-d5);
    font-weight: var(--semibold);
    background: #fff9e7;
    background: var(--warning-l8);
    border: 1px solid #e6ab0a;
    border: 1px solid var(--warning-d1);
    border-top: none;
    border-radius: 0 0 3px 3px;
  }
}

// radios

.form-group.formio-component-radio {
  .form-check-inline,
  .form-check {
    .form-check-label {
      position: relative;
      input {
        position: absolute;
        right: 0;
        opacity: 0;
      }
    }
  }
}

// radio & multi-checkbox

.form-group.formio-component-radio {
  .form-radio {
    display: flex;
    border: 1px solid var(--primary-l4);
    border-radius: 4px;
    background: #fff;
    outline: none;
    transition: border 197ms ease-out;
    .form-check-inline,
    .form-check {
      flex-grow: 1;
      label {
        display: block;
        padding: var(--spacing-m1) var(--spacing-m2) var(--spacing-m1) var(--spacing-m1);
        transition: background 131ms ease-out,box-shadow 131ms ease-out;
        border-right: 1px solid var(--primary-l6);
        &:hover {
          cursor: pointer;
        }
      }
      &:last-child label {
        border-right: none;
      }
    }
  }
}

// radio:before

.form-group.formio-component-radio {
  .form-radio {
    .form-check-inline label,
    .form-check label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .form-check-inline label:before,
    .form-check label:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 15px;
      margin-right: var(--spacing-m1);
      background: #fff;
      box-shadow: 0 0 0 1px #aaa;
      box-shadow: 0 0 0 1px var(--primary-l3);
      vertical-align: middle;
      border-radius: 10px;
      transition: all 183ms ease-out;
    }
  }
}

// checked

.form-group.formio-component-radio {
  .form-radio {
    .form-check-inline.radio-selected,
      .form-check.radio-selected {
      label:before {
        background: var(--success);
      }
    }
  }
}

// radio error

.form-group.formio-component-radio.has-message {
  .form-radio {
    border: 1px solid var(--warning-d1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// focus

.form-group {
  clear: both;
  input[type='text']:focus,
  input[type='email']:focus {
    border: 1px solid var(--primary-d1);
  }
}

.form-group.formio-component-radio {
  .form-radio {
    &:focus-within,
    &:active {
      border: 1px solid var(--primary-d1);
    }
  }
}

// stacked radios

.form-group.formio-component-radio.radio-stacked {
  .form-radio {
    flex-direction: column;
    .form-check-inline,
    .form-check {
      label {
        border-right: none;
        border-bottom: 1px solid var(--primary-l6);
      }
      &:last-child {
        label {
          border-bottom: none;
        }
      }
    }
  }
}

// .form-group.formio-component-radio.radio-stacked {
//   .form-radio {
//     .form-check,
//     .form-check-inline {
//       label {
//         border-right: 1px solid red;
//       }
//     }
//   }
// }

// currency fields

.input-group {
  display: flex;
  align-items: center;
  .input-group-prepend,
  .input-group-append {
    background: var(--primary);
    font: inherit;
    line-height: inherit;
    color: inherit;
    padding: var(--spacing-m1);
    border: 1px solid var(--primary);
    color: #fff;
  }
  input {
    border-radius: 4px 0 0 4px;
  }
  .input-group-prepend + input {
    border-radius: 0 4px 4px 0;
  }
  .input-group-prepend {
    border-radius: 4px 0 0 4px;
  }
  .input-group-append {
    border-radius: 0 4px 4px 0;
  }
}

// hide font awesome icon on datepicker

.form-group.formio-component-datetime {
  .input-group-append {
    display: none;
  }
  input {
    border-radius: 4px;
  }
}

// hide autocomplete fields

.formio-select-autocomplete-input {
  display: none;
}

// date fields 

.form-group.formio-component-day {
  .col {
    margin-bottom: 1rem;
    label {
      font-size: 90%;
    }
  }
}

@media (min-width: 768px) {
  .form-group.formio-component-day {
    .col {
      width: 32%;
      float: left;
      &:nth-child(2) {
        margin-left: 2%;
        margin-right: 2%;
      }
    }
  }
}

// inline help txt

.form-group .text-muted {
  font-size: 0.85rem;
  padding-top: var(--spacing-m1);
  color: var(--grey-d1);
  font-style: italic;
}

// inline warning txt

.warning-txt {
  display: inline-block;
  padding-top: var(--spacing-m1);
  color: var(--accent-d1);
}

.reduce-reason, .reduce-reason-other {
  margin-bottom: 0.5rem;
}

.under-maintenance-warning {
  margin-top: 2rem;
}