.HelpIndex {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    a {
      display: block;
      min-height: 30px;
      padding: var(--spacing-m1) var(--spacing-m1);
      border-radius: var(--border-radius-m2);
      transition: background 161ms ease-out;
      &:hover {
        background: var(--primary-l7);
      }
    }
  }
}

@media (min-width: 768px) {
  .HelpIndex {
    li {
      width: 50%;
      float: left;
    }
  }
}